import React, { useEffect } from "react";
import Footer from "../components/Footer";
import { motion, transform } from "framer-motion";

const variants = {
  open: {
    opacity: 0,
  },
  closed: {
    opacity: 1,
    transition: {
      staggerChildren: 1,
      when: "beforeChildren",
      delay: 3.2,
    },
  },
};
const childVariants = {
  open: {
    opacity: 0,
  },
  closed: {
    opacity: 1,
  },
};

const Home = () => {
  // useEffect(() => {
  //   const script = document.createElement('script');

  //   script.src = "https://dpr.leadplus.com.au/main.js";
  //   // script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  const duration = 5;
  return (
    <div className="flex justify-center p-4 pt-0 pb-0 flex-col h-full w-full ">
      <div>
        {/* <motion.div
          className="text-center flex flex-col items-center "
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2.5 }}
        >
          <h2 className="text-xl font-bold mt-2">
            What's your property worth?
          </h2>
          <p className="pt-2">
            Enter your property address to receive an instant estimate for your
            home
          </p>
          <div className="w-full">
            <input
              type="text"
              placeholder="Start typing to find your address"
              className=" border-2 p-4 border-black w-full my-2 rounded-lg"
            />
            <button className="bg-black text-white p-4 py-3 rounded-lg text-center w-full">
              Find out
            </button>
          </div>
        </motion.div> */}

        <motion.div
          variants={variants}
          initial="open"
          animate="closed"
          transition={{ delay: 2.5 }}
        >
          {/* LINE ANIMATION */}
          <div className="text-center my-5 font-bold flex justify-center items-center  w-full">
            <motion.hr
              animate={{
                opacity: 1,
                rotate: [90, 90, 180, 270, 360, 450, 540],
                width: [50, 50, 150, 50, 150, 50, 150],
              }}
              transition={{
                ease: "easeInOut",
                rotate: {
                  delay: 2.5,
                  duration,
                },
                width: {
                  delay: 2.5,
                  duration,
                },
              }}
              className="w-12 border-black border-t-4 rotate-90 my-3 md:hidden"
            />
              <motion.hr
              animate={{
                opacity: 1,
                rotate: [90, 90, 180, 270, 360, 450, 540],
                width: [150, 150, 250, 150, 250, 150, 250],
              }}
              transition={{
                ease: "easeInOut",
                rotate: {
                  delay: 2.5,
                  duration,
                },
                width: {
                  delay: 2.5,
                  duration,
                },
              }}
              className="w-12 border-black border-t-4 rotate-90 my-3 hidden md:flex"
            />
          </div>

          <div className="  flex  flex-col justify-center ">
            <div className="flex flex-col gap-2 md:gap-5 mx-auto w-full md:max-w-[720px] lg:max-w-[1250px]">
              <motion.a
                variants={childVariants}
                className="bg-black text-white p-4 py-3 md:py-5  text-center rounded-lg w-full"
                href="https://www.hodgeestateagents.com.au/buy"
              >
                Buy
              </motion.a>
              <motion.a
                variants={childVariants}
                className="bg-black text-white p-4 py-3 md:py-5 text-center rounded-lg w-full"
                href="https://www.hodgeestateagents.com.au/sell"
              >
                Sell
              </motion.a>
              <motion.a
                variants={childVariants}
                className="bg-black text-white p-4 py-3 md:py-5 text-center rounded-lg w-full"
                href="https://www.hodgeestateagents.com.au/rent"
              >
                Rent
              </motion.a>
              <motion.a
                variants={childVariants}
                className="bg-black text-white p-4 py-3 md:py-5 text-center rounded-lg w-full"
                href="https://www.hodgeestateagents.com.au/manage"
              >
                Manage
              </motion.a>
            </div>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2 }}
        >
          <Footer />
        </motion.div>
      </div>
    </div>
  );
};

export default Home;
