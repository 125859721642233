import React from 'react'
import ReactDOM from 'react-dom';
import { motion } from 'framer-motion';
import { TypeAnimation } from "react-type-animation";
const Header = () => {
  return ReactDOM.createPortal(
    <div className="flex justify-center p-4 pt-0 pb-0 flex-col items-center h-full">
    <motion.div
        initial={{ y: 350 }}
        animate={{ y: 0 }}
        transition={{ delay: 2, duration: 0.2 }}
      >
        <a href="https://hodgeestateagents.com.au" target="self">
        <TypeAnimation
          className="font-bold text-5xl md:text-8xl mb-5 mt-3"
          sequence={[
            "h",
            200,
            "ho",
            200,
            "hod",
            200,
            "hodg",
            200,
            "hodge",
            200,
            "hodge.", // Types 'Three' without deleting 'Two'
            () => {
              console.log("Done typing!"); // Place optional callbacks anywhere in the array
            },
          ]}
          wrapper="div"
          cursor={false}
        />
        </a>
      </motion.div>
      </div>,
    document.getElementById('header')
  )
}

export default Header