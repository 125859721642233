import React from "react";

const Footer = () => {
  const date = new Date().getFullYear()
  
  return (
    <div>
      <div className="flex flex-col items-center text-center mt-5 md:mt-20">
        <h1 className="font-bold text-sm md:text-lg">Social Media Landing Page</h1>
        <p className="text-[12px] md:text-lg">Copyright © Hodge Estate Agents {date}. </p>
        <p className="text-[12px] md:text-lg">All rights reserved</p>
        <div className="text-[12px] md:text-lg flex gap-1 md:gap-3">
          <a href=" https://www.hodgeestateagents.com.au/privacy">Privacy Policy</a>
          {/* |
          <a href="https://www.hodgeestateagents.com.au/privacy"> Terms of Use</a> */}
          |
          <a href=" https://www.hodgeestateagents.com.au">Visit Website</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
